<template>
  <label class="input-file">
    <div v-if="avatar" class="new-child__avatar-img" >
      <img
        :src="avatarPreview"
        alt="Photo"
      >
    </div>
    <div
      v-else class="new-child__avatar-img"
      @click="chooseImage(1, $event)"
    >
    </div>

    <div
      class="new-child__avatar-btn"
      @click="chooseImage(2, $event)"
    >
      <CameraSVG /><span>Select photo</span>
    </div>
    <input
      type="file"
      :ref="refName"
      :disabled="disabled"
      accept=".jpg, .png, .gif, .jpeg"
      :hidden="true"
      @change="handleFileUpload"
    />
    
    <p class="form__error">{{errorMessage}}</p>
  </label>
</template>

<script>
import CameraSVG from '@/assets/images/icons/icon-camera.svg'

export default {
  components: {CameraSVG},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    fieldsData: {
      type: Object,
      default: () => {},
    },
    stepStatus: {
      type: String,
      default: '',
    },
    refName: {
      type: String,
      required: true
    },
  },
  data: () => ({
    maxSizeError: false,
    errorMessage: '',
    value: [],
    avatarPreview: null,
    avatar: null,
  }),
  mounted() {
    this.avatar = this.fieldsData.value
    this.avatarPreview = this.fieldsData.value && this.fieldsData.files ? this.fieldsData.files.url : ''
  },
  methods: {
    chooseImage(col, event) {
      this.errorMessage = ''
      event.preventDefault()
      event.stopPropagation()
      if (this.$refs[this.refName]) {
        this.$refs[this.refName].click()
      }
    },
    clear(){
      this.avatar = null
      this.avatarPreview = null
      this.$refs[this.refName].reset()
    },
    handleFileUpload(e) {
      const file = e.target.files[0]

      const maxSize = 1
      const filesize = ((file.size/1024)/1024).toFixed(4)

      if(filesize > maxSize) {
        this.maxSizeError = true
        this.errorMessage = 'The size of the uploaded image is too large.'
        this.$emit('input', null)
        return
      }

      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.avatarPreview = reader.result
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }

      this.avatar = file
      this.fieldsData.value = this.avatar

      this.$emit('input')
      this.$emit('addFiles', [this.avatar])
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/colors/colors';
.new-child {
  &__avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-img {
      background: #EDEDED;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-bottom: 10px;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    &-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: $primary;
      svg > g > path {
        stroke: $primary;
      }
      span {
        margin-left: 5px;
      }
    }
  }
}

</style>
<style lang="scss" scoped>
  .input-file {
    display: block;

    &-name{
      overflow: hidden;
    }
  }
</style>
