<template>
  <div>
    <label
      v-if="showTextField(fieldsData.type)"
      class="form__row"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <input
              v-model="value"
              :type="fieldsData.type"
              :name="fieldsData.name"
              class="input"
              :disabled="isDisabledField"
              :class="{
                'is-invalid': (fieldsData.required && error && !value) || (fieldsData.required && $v.value.$dirty && !$v.value.required),
                'disabled': isDisabledField 
              }"
              :required="fieldsData.required"
              @input="validate"
              @change="emitChanges"
      />
    </label>

    <label
      v-if="fieldsData.type === 'tel'"
      class="phone__row"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <MazPhoneNumberInput
        :value="value"
        class="phone-number"
        no-flags
        :default-phone-number="defaultPhoneNumber"
        :class="{
          'is-invalid': 
             (fieldsData.required && error && !value) 
          || (fieldsData.required && $v.value.$dirty && !$v.value.required)
          || (enteredPhoneNumber && !enteredPhoneNumber.isValid),
          'disabled': isDisabledField 
        }"
        :disabled="isDisabledField"
        no-validation
        @update="emitPhoneNamberChanges($event)"
      />
      <p 
        v-if="enteredPhoneNumber && !enteredPhoneNumber.isValid"
        class="error"
      >
        Valid phone number is required
      </p>
    </label>

    <label
      v-if="fieldsData.type === 'long_text'"
      class="form__row"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <textarea
              v-model="value"
              :required="fieldsData.required"
              :name="fieldsData.name"
              :disabled="isDisabledField"
              :class="{
                'is-invalid': (fieldsData.required && error && !value) || (fieldsData.required && $v.value.$dirty && !$v.value.required),
                'disabled': isDisabledField
              }"
              @input="validate"
              @change="emitChanges"
      ></textarea>
    </label>

    <label
      v-if="fieldsData.type === 'date'"
      class="form__row"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <date-picker
              v-model="value"
              input-class="input-date"
              valueType="format"
              placeholder="Select"
              :disabled="isDisabledField"
              :class="{
                'is-invalid': (fieldsData.required && error && !value) || (fieldsData.required && $v.value.$dirty && !$v.value.required),
                'disabled': isDisabledField
              }"
              @input="validate"
              @change="emitChanges"
      ></date-picker>
    </label>

    <label
      v-if="fieldsData.type === 'dob'"
      class="form__row"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <date-picker
              v-model="value"
              input-class="input-date"
              valueType="format"
              placeholder="Select"
              :disabled="isDisabledField"
              :disabled-date="(date) => date >= new Date()"
              :class="{
                'is-invalid': (fieldsData.required && error && !value) || (fieldsData.required && $v.value.$dirty && !$v.value.required),
                'disabled': isDisabledField
              }"
              @input="validate"
              @change="emitChanges"
      ></date-picker>
    </label>

    <label
      v-if="fieldsData.type === 'dropdown'"
      class="form__row"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <div class="input-select">
        <select
                v-model="value"
                class="input"
                :disabled="isDisabledField"
                :class="{
                  'is-invalid': (fieldsData.required && error && (value == null || value.length == 0)) || (fieldsData.required && $v.value.$dirty && !$v.value.required),
                  'disabled': isDisabledField
                }"
                @blur="$v.value.$touch"
                :required="fieldsData.required"
                @change="emitChanges"
        >
          <option
                  v-for="(option, index) in fieldsData.options"
                  :key="index"
                  :value="option.value"
          >{{option.text}}</option>
        </select>
      </div>
    </label>

    <div
      v-if="fieldsData.type === 'multi_select'"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <div>
        <v-select
                class="multiple-select"
                placeholder="Select"
                multiple
                :disabled="isDisabledField"
                v-model="value"
                :closeOnSelect="false"
                :options="fieldsData.options"
                label="name"
                :deselectFromDropdown = "true"
                :class="{'is-invalid': (fieldsData.required && error && (value == null || value.length == 0)) || (fieldsData.required && $v.value.$dirty && !$v.value.required) }"
                @input="emitChanges"
        >
          <template
            #option="{ name }"
          >
            <span>{{name}}</span>
          </template>
        </v-select>
      </div>
    </div>

    <div
      v-if="fieldsData.type === 'checkbox'"
      class="form__row"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <label
        v-for="(option, index) in fieldsData.options"
        :key="index"
        class="nice-checkbox"
      >
        <input
          type="checkbox"
          v-model="value"
          :disabled="isDisabledField"
          :class="{
            'is-invalid': (fieldsData.required && error && !value) || (fieldsData.required && $v.value.$dirty && !$v.value.required),
            'disabled': isDisabledField
          }"
          :value="option"
          @input="validate"
          @change="emitChanges"
        />
        <span>{{option}}</span>
      </label>
    </div>

    <div
      v-if="fieldsData.type === 'radio_button'"
      class="form__row"
    >
      <span
        class="form__row-label"
        :class="{
            'is-invalid': (fieldsData.required && error && !value) || (fieldsData.required && $v.value.$dirty && !$v.value.required),
          }"
      >{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <label
        v-for="(option, index) in fieldsData.options"
        :key="index"
        class="nice-radio"
      >
        <input
          type="radio"
          v-model="value"
          :disabled="isDisabledField"
          :class="{
            'is-invalid': (fieldsData.required && error && !value) || (fieldsData.required && $v.value.$dirty && !$v.value.required),
            'disabled': isDisabledField
          }"
          :value="option"
          @change="emitChanges"
        />
        <span>{{option}}</span>
      </label>
    </div>

    <div
      v-if="fieldsData.type === 'file_upload'"
      class="form__row"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <file-select
        :fields-data="fieldsData"
        :class="{
          'is-invalid': (fieldsData.required && error && (value == null || value.length == 0)),
          'disabled': isDisabledField
        }"
        :disabled="isDisabledField"
        :step-status="stepStatus"
        @input="validate"
        @addFiles="addFiles"
      />
      <div 
        v-if="value && !isDisabledField"
        class="files"
      >
        <div 
          v-for="(file, index) in value"
          :key="index"
          class="file"
        >
          <FileSVG />
          <span v-if="isFile(file)">{{ file.name }}</span>
          <a :href="findFileUrlByPath(file)" target="_blank" v-else>{{ findFileNameByPath(file) }}</a>
          <span class="file_remove" @click="fileRemove(index)"></span>
        </div>
      </div>
    </div>

    <div
      v-if="fieldsData.type === 'plain_text'"
      class="plain-text"
    >
      <p v-html="fieldsData.label"></p>
    </div>

    <div 
      v-if="fieldsData.type === 'group_selection'"
    >
      <ApplicationAddGroupType
        :isDisabledField="isDisabledField"
        :isInvalid="(fieldsData.required && error && (value == null || value.length == 0)) || (fieldsData.required && $v.value.$dirty && !$v.value.required)"
        :groupData="fieldsData"
        :groupValue="fieldValue"
        @change="emitChangesOnGroupSelection"
      />
    </div>

    <div
      v-if="fieldsData.type === 'payment_field'"
    >
      <PaymentWizardOption
        :option="fieldsData"
        :isDisabledField="isDisabledField"
      />
    </div>

    <div
      v-if="fieldsData.type === 'signature'"
    >
      <signature
        :ref="'signature_' + fieldsData.ref"
        :fields-data="fieldsData"
        :is-disabled-field="isDisabledField"
        :ref-name="fieldsData.ref"
      />
    </div>

    <div
      v-if="fieldsData.type === 'session_selection'"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <div>
        <v-select
          class="multiple-select"
          placeholder="Select"
          :multiple="!limitSessionSelection"
          :closeOnSelect="false"
          :options="sessionsList"
          :disabled="isDisabledField"
          label="name"
          v-model="value"
          :selectable="session => isSessionSelectable(session)"
          :deselectFromDropdown = "true"
          :class="{'is-invalid': (fieldsData.required && error && (value == null || value.length == 0)) || (fieldsData.required && $v.value.$dirty && !$v.value.required) }"
          @input="emitChangesOnSessionSelection"
        >
          <template
            #option="{ name, price }"
          >
            <span>{{name}}</span> <span v-if="price !== 0 && !hidePrice">${{price | toFixedNumber}}</span>
          </template>
        </v-select>
      </div>
    </div>

    <label
      v-if="fieldsData.type === 'email'"
      class="form__row"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <input
              v-model="value"
              type="email"
              :name="fieldsData.name"
              class="input"
              :disabled="isDisabledField"
              :class="{
                'is-invalid': 
                   (fieldsData.required && error && !value) 
                || (fieldsData.required && $v.value.$dirty && !$v.value.required)
                || ($v.value.$dirty && !$v.value.email)
                ,
                'disabled': isDisabledField 
              }"
              :required="fieldsData.required"
              @blur="validate"
              @change="emitChanges"
      />
      <p 
        v-if="$v.value.$dirty && !$v.value.email"
        class="error"
      >
        Valid email required
      </p>
    </label>

    <div
      v-if="fieldsData.type === 'avatar'"
      class="form__row"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>

      <image-select
        :fields-data="fieldsData"
        :class="{
          'is-invalid': (fieldsData.required && error && (value == null || !value)),
          'disabled': isDisabledField
        }"
        :disabled="isDisabledField"
        :step-status="stepStatus"
        :ref-name="fieldsData.ref"
        @input="validate"
        @addFiles="addFiles"
      />
    </div>
  </div>
</template>

<script>
import ApplicationAddGroupType from '@/views/Application/ApplicationAddGroupType.vue'
import useApplicationAdd from '@/views/Application/useApplicationAdd'
import { required, email } from 'vuelidate/lib/validators'

import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'

import 'vue2-datepicker/index.css'
import 'vue-select/dist/vue-select.css'

import { MazPhoneNumberInput } from 'maz-ui'
import "maz-ui/lib/css/base.css"
import "maz-ui/lib/css/maz-phone-number-input.css"

import FileSelect from '@/components/site/FileSelect.vue'
import PaymentWizardOption from '@/components/site/PaymentWizardOption.vue'
import Signature from '@/components/site/Signature.vue'
import { mapGetters } from 'vuex'
import store from '@/store'
import FileSVG from '@/assets/images/icons/icon-file.svg'

import {
  capitalizeFirstLetter,
} from '@/helpers/inputFormater'
import ImageSelect from "@/components/site/ImageSelect.vue";

export default {
  components: {
    ImageSelect,
    vSelect,
    DatePicker,

    FileSelect,
    ApplicationAddGroupType,
    PaymentWizardOption,
    Signature,

    FileSVG,
    MazPhoneNumberInput,
  },
  filters: {
    toFixedNumber(value) {
      return value.toFixed(2)
    }
  },
  props: {
    fieldsData: {
      type: Object,
      default: () => {},
    },
    fieldValue: {
      type: null,
    },
    stepStatus: {
      type: String,
      default: '',
    },
    stepId: {
      type: String,
      default: '',
    },
    stepRequirementOption: {
      type: String,
      default: '',
    },
    validTracker: {
      type: Boolean,
      default: false
    },
    stepHasPaymentWizard: {
      type: Boolean,
      default: false
    },
    checkValue: {
      type: Boolean,
      required,
    },
    isDisabledEditing: {
      type: Boolean,
      required,
    },
  },
  data: () => ({
    error: false,
    value: null,
    defaultPhoneNumber: null,
    enteredPhoneNumber: null,
  }),
  watch: {
    validTracker(val) {
      if (val > 0 && !this.value?.length) this.error = true
    },
    fieldValue() {
      this.setValue()
    },
    fieldsData: {
      deep: true,
      handler() {
        this.setValue()
      },
    },
    checkValue() {
      this.setValue()
    },
    chosenSessions() {
      if (this.sessionsList.filter(row => this.chosenSessions.includes(row.id) && row.front_check).length < 1) {
        this.sessionsList.forEach(row => {
          row.allowed_individual_selection = row.front_check
        })
        return
      }

      this.sessionsList.forEach(row => {
        row.allowed_individual_selection = true
      })
    },
    'fieldsData.hidden': function(val) {
      if (val) {
        store.commit('applicationRegistration/CHECK_CONDITIONS')
        this.$emit('checkConditions')
      }
    },
  },
  validations() {
    if (this.fieldsData.type == 'email') {
      return {
        value: {
          required,
          email,
        }
      }
    } else {
      return {
        value: {
          required,
        }
      }
    }
  },
  setup() {
    const {
      removeFile,
      formBuilderFieldTypes,
    } = useApplicationAdd()

    return {
      removeFile,
      formBuilderFieldTypes,
    }
  },
  mounted() {
    this.setValue()
  },
  created() {
    if (this.fieldsData.type === 'tel' && this.fieldsData.value) this.defaultPhoneNumber = this.fieldsData.value
  },
  computed: {
    ...mapGetters({
      sessionsList: 'applicationRegistration/getSessionsList',
      hidePrice: 'applicationRegistration/getHidePrice',
      limitSessionSelection: 'applicationRegistration/getLimitSessionSelection',
    }),
    chosenSessions: {
      get: function () {
        return store.getters["applicationRegistration/getChosenSessions"]
      },
      set: function (val) {
        store.commit('applicationRegistration/SET_CHOSEN_SESSIONS', val)
      }
    },
    isDisabledField() {
      return this.isDisabledEditing
    },
    isDisabledSignatureField() {
      return !['', 'Draft', 'Rejected'].includes(this.stepStatus)
    },
  },
  methods: {
    isSessionSelectable(session) {
      if (session.allowed_individual_selection) {
        return true
      }

      return Array.isArray(this.value) && this.value?.length
    },
    setValue() {
      if (this.fieldsData.value && this.fieldsData.type === 'session_selection') {
        const availableSessionIds = this.sessionsList.map(session => session.id)
        this.value = this.fieldsData.value.filter(session => availableSessionIds.includes(session.id))
      } else {
        this.value = this.fieldsData.value
      }
    },
    isFile(item) {
      return item instanceof Blob
    },
    showTextField(type) {
      return ~['text', 'number'].findIndex(item => item === type)
    },
    async validate() {
      if (this.fieldsData.type == 'text') {
        this.value = capitalizeFirstLetter(this.value)
        this.emitChanges()
      }
      if (this.$v.$invalid){
        await this.$v.$touch();
      }
    },
    emitChanges() {
      this.fieldsData.value = this.value
      store.commit('applicationRegistration/CHECK_CONDITIONS')
      this.$emit('checkConditions')
    },
    emitChangesOnGroupSelection(val) {
      this.value = val
      store.commit('applicationRegistration/CHECK_CONDITIONS')
      this.$emit('checkConditions')
    },
    emitChangesOnSessionSelection() {
      if (!Array.isArray(this.value)) {
        this.value = this.value ? [this.value] : []
      }

      if (this.value.length) {
        const selectableSessionIds = this.value.map(session => session.id)
        const availableIndividualSelection = this.sessionsList
          .filter(session => session.allowed_individual_selection)
          .filter(session => selectableSessionIds.includes(session.id))

        if (!availableIndividualSelection.length) {
          this.value = null
          return
        }
      }

      this.value = this.value.map(session => ({
        id: session.id,
        name: session.name,
      }))
      this.chosenSessions = this.value
      this.emitChanges()
    },
    addFiles(files) {
      if (this.stepStatus === "Draft") {
        if (Array.isArray(this.fieldsData.value)) {
          this.fieldsData.value.push(...Array.from(files.map(file => file.file_path)))
        } else {
          this.fieldsData.value = Array.from(files.map(file => file.file_path))
        }
        const filesDetails = Array.from(files.map(file => ({
          'name': file.file_path,
          'path': file.file_path,
          'url': file.file_url,
        })))
        if (Array.isArray(this.fieldsData.files)) {
          this.fieldsData.files.push(...filesDetails)
        } else {
          this.fieldsData.files = filesDetails
        }

      } else {

        if (Array.isArray(this.fieldsData.value)) {
          this.fieldsData.value.push(...Array.from(files))
        } else {
          this.fieldsData.value = files.length > 1 ? Array.from(files) : files[0]
        }
      }
    },
    fileRemove(index) {
      if(this.stepStatus === "Draft") {
        this.removeFile({ file_path: this.fieldsData.value[index] })
        this.fieldsData.value.splice(index, 1)
      } else {
        this.fieldsData.value.splice(index, 1)
      }
    },
    findFileUrlByPath(path) {
      if (!this.fieldsData.files) {
        return ''
      }
      return this.fieldsData.files.filter(file => file.path === path)[0]?.url
    },
    findFileNameByPath(path) {
      if (!this.fieldsData.files) {
        return ''
      }
      return this.fieldsData.files.filter(file => file.path === path)[0]?.path
    },
    emitPhoneNamberChanges(e) {
      if (e.formatInternational) {
        this.enteredPhoneNumber = e
        this.value = e.formatInternational
        this.emitChanges()
      }
    },
  },
}
</script>

<style lang="scss">
@import 'src/assets/scss/colors/colors';

.mx-datepicker{
  &.is-invalid{
    .input-date {
      border: 1px solid var(--error) !important;
      &::placeholder {
        color: var(--error)
      }
    } 
  }
  &.disabled{
    .input-date{
      cursor: not-allowed;
      background-color: #cccccc;
      opacity: 0.7;
    }
  }
  
}
.multiple-select{
  margin-bottom: 30px;
}
.plain-text{
  margin: 30px 0;
  word-break: break-word;
  h1, h2, h3, h4, h5, h6, p, em, img, strong, sub, sup, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, a, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
    margin: revert;
    padding: revert;
    font-size: revert;
    list-style: revert;
  }
  img, video {
    max-width: 100%;
  }
}
.files {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.file {
  padding: 10px;
  position: relative;
  width: 32%;
  word-break: break-all;
  a {
    color: $secondary;
  }
  svg {
    height: 20px;
  }
  svg path, svg rect {
    fill: $primary;
  }
  &_remove {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
    width: 15px;
    height: 15px;
    cursor: pointer;

    &::before,&::after {
      content: '';
      display: block;
      position: absolute;
      left: 6px;
      width: 1px;
      height: 15px;
      background: $secondary;
      transform: rotate(45deg);
    }
    &::after{
      transform: rotate(-45deg);
    }
  }

}
.phone__row{
  width: 100%;
  margin-bottom: 30px;
}
.phone-number {
  border-radius: 12px;
  .maz-input {
    height: 60px;
    background: #EDEDED;
    border: none !important;
    border-color: none !important;
  }
  .maz-input__toggle-btn {
    display: none;
  }
  &.is-invalid{
    border: 1px solid var(--error) !important;
    &::placeholder {
      color: var(--error)
    }
  }
}
</style>
<style scoped>
.error {
  position: absolute;
  color: red;
  font-size: 14px;
}
</style>
